/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import {
  AppBar, Button, makeStyles, Toolbar,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Context from '../Context';
import packageJson from '../../package.json';

const useStyles = makeStyles((theme) => ({
  topbar: {
    background: 'transparent!important',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '60px',
    },
  },
  title: {
    flexGrow: 1,
  },
  button: {
    color: 'black',
    minWidth: '10px',
  },
  versionText: {
    color: 'grey',
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
}));

export default function TopBar() {
  const classes = useStyles();
  const { user, signOut } = useContext(Context);

  const logout = (e) => {
    e.preventDefault();
    signOut(() => {

    });
  };
  localStorage.setItem('version', packageJson.version)
  return (
<AppBar position="absolute" className={classes.topbar}>
  <Toolbar>
    <div className={classes.title} />
    <p className={classes.versionText} style={{ marginRight: '16px' }}>Version: {packageJson.version}</p>
    {user && (
      <Button
        onClick={logout}
        title="Sign out"
        className={classes.button}
        style={{ backgroundColor: '#f0f0f0', marginTop: '8px' }}
      >
        <span>Sign out</span>
      </Button>
    )}
  </Toolbar>
</AppBar>
  );
}
