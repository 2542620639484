/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Controller, useForm } from 'react-hook-form';
import { getAuth } from '../auth';
import Context from '../Context';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '100%',
    textAlign: 'center',
  },
}));

export default function LoginForm() {
  const classes = useStyles();
  const { user, signIn, signing, loading } = useContext(Context);
  const { handleSubmit, reset, control } = useForm();
  const onSubmit = async (data) => {
    // Set loading to true to show the loading indicator
    setSigningIn(true);
    try {
      // Perform the sign-in operation
      await signIn(data.groupId);
    } catch (error) {
    } finally {
      // Regardless of success or failure, set loading to false when the operation is complete
      setSigningIn(false);
    }
  };

  const [signingIn, setSigningIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    reset(auth);
  }, [reset]);

  useEffect(() => {
    console.log('user changed', user);
  }, [user]);

  if (loading) return null;

  return (
    <form data-testid="login-form" id="login-form" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="groupId"
        control={control}
        render={({ field }) => (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Group ID"
            autoComplete="groupId"
            autoFocus
            {...field}
          />
        )}
      />

      <div className={classes.submit}>
        <Button type="submit" variant="contained" color="primary" disabled={signing}>
        {signingIn ? (
            <div className="loader"></div>
          ) : (
            'Sign In'
          )}

        </Button>
        {signingIn ? <p>Signing you in ...</p> : null}
        
      </div>
    </form>
  );
}
