import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { DynamoDBDocumentClient, UpdateCommand, QueryCommand } from '@aws-sdk/lib-dynamodb';

import { Auth } from 'aws-amplify';
import sha256 from 'crypto-js/sha256';
import config from './config';

const marshallOptions = {
  // Whether to automatically convert empty strings, blobs, and sets to `null`.
  convertEmptyValues: false, // false, by default.
  // Whether to remove undefined values while marshalling.
  removeUndefinedValues: false, // false, by default.
  // Whether to convert typeof object to map attribute.
  convertClassInstanceToMap: false, // false, by default.
};

const unmarshallOptions = {
  // Whether to return numbers as a string instead of converting them to native JavaScript numbers.
  wrapNumbers: false, // false, by default.
};

const translateConfig = { marshallOptions, unmarshallOptions };

let dynamodbClient;
let timeout;
const TIMEOUT_CONN = 10 * 1000; // 10 sec

let credentialsBuffer;
export const getCredentials = async () => {
  if (!credentialsBuffer || Date.now() > timeout + TIMEOUT_CONN) {
    timeout = Date.now();
    await Auth.currentAuthenticatedUser();
    credentialsBuffer = await Auth.currentCredentials();
  }
  return credentialsBuffer;
};

const getDynamoDB = async () => {
  const credentials = await getCredentials();
  const dynamodb = new DynamoDBClient({
    region: config.dynamoDbRegion,
    credentials,
  });
  dynamodbClient = DynamoDBDocumentClient.from(dynamodb, translateConfig);
  return dynamodbClient;
};

export const getLocalMetadata = () => {
  const groupId = window.localStorage.getItem('groupId');
  const d = JSON.parse(localStorage.getItem(`metadataV3_${groupId}`));
  return d || {};
};

export const storeMetadata = async (data) => {
  const groupId = window.localStorage.getItem('groupId');
  data.firstname = data.firstname.trim();
  data.lastname = data.lastname.trim();

  const arr = [
    groupId,
    data.firstname.toLowerCase(),
    data.lastname.toLowerCase(),
    data.gender,
    data.age,
    data.accent,
  ];

  data.hash = sha256(arr.join('-')).toString();
  window.localStorage.setItem(`metadataV3_${groupId}`, JSON.stringify(data));
};

export const getLeadership = async () => {
  const groupId = window.localStorage.getItem('groupId');
  const db = await getDynamoDB();

  const data = await db.send(new QueryCommand({
    IndexName: 'numRecordings-index',
    ScanIndexForward: false,
    TableName: config.leadershipTable,
    KeyConditionExpression: 'campaignId = :gameId',
    ExpressionAttributeValues: {
      ':gameId': `${groupId}-${new Date().toISOString().split('T')[0]}`,
    },
    Limit: 100,
  }));

  return data.Items;
};

export const ackRecording = async (duration) => {
  const db = await getDynamoDB();
  const groupId = window.localStorage.getItem('groupId');
  const microphone = localStorage.getItem('selectedMicrophone');
  const version = localStorage.getItem('version');
  const { hash, firstname, lastname, gender, age, accent } = getLocalMetadata();

  const rd = new Date().toISOString().split('T')[0];
  const params = {
    TableName: config.leadershipTable,
    Key: { userHash: hash, campaignId: `${groupId}-${rd}` },
    UpdateExpression: 'SET recordingLength = if_not_exists(recordingLength, :start) + :incr, firstname = :firstname, lastname = :lastname, gender = :gender, age = :age, accent = :accent, recordingDate = :rd, groupId = :gid, microphone = :microphone, version = :version',
    ExpressionAttributeValues: {
      ':incr': duration,
      ':start': 0,
      ':firstname': firstname,
      ':lastname': lastname,
      ':gender': gender,
      ':age': age,
      ':accent': accent,
      ':rd': rd,
      ':gid': groupId,
      ':microphone' : microphone,
      ':version' : version
    },
    ReturnValues: 'ALL_NEW',
  };

  const data = await db.send(new UpdateCommand(params));
  return data.Attributes;
};

export const ackUpload = async () => {
  const db = await getDynamoDB();
  const groupId = window.localStorage.getItem('groupId');
  const microphone = localStorage.getItem('selectedMicrophone');
  const { hash, firstname, lastname, gender, age, accent } = getLocalMetadata();

  const rd = new Date().toISOString().split('T')[0];
  const GMTtime = new Date(). toUTCString();
  const updatedAtDatetime = new Date(GMTtime).toISOString();
  const params = {
    TableName: config.leadershipTable,
    Key: { userHash: hash, campaignId: `${groupId}-${rd}` },
    UpdateExpression: 'SET numRecordings = if_not_exists(numRecordings, :start) + :incr, firstname = :firstname, lastname = :lastname, gender = :gender, age = :age, accent = :accent, recordingDate = :rd, groupId = :gid, updatedAt = :updatedAt, microphone = :microphone',
    ExpressionAttributeValues: {
      ':incr': 1,
      ':start': 0,
      ':firstname': firstname,
      ':lastname': lastname,
      ':gender': gender,
      ':age': age,
      ':accent': accent,
      ':rd': rd,
      ':gid': groupId,
      ':updatedAt': updatedAtDatetime,
      ':microphone' : microphone
    },
    ReturnValues: 'ALL_NEW',
  };

  // console.log("Device : " + {deviceId})

  const data = await db.send(new UpdateCommand(params));
};
